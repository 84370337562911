import {Component} from "vue-property-decorator"
import {VNode} from "vue"

import BackendClientMixin from "@/mixins/BackendClientMixin"
import TableComponent from "@/components/TableComponent"
import {BIcon, BIconArrowLeft, BIconArrowRight, BIconPlusCircle, BIconSortDown, BIconSortDownAlt} from "bootstrap-vue";

import {activityStore, PaginationPayload, SortBy} from "@/_modules/activity/store/activity"
import {authStore} from "@/store"

import * as ProposalRoutes from "@/_modules/proposal/router/proposal-demand-routes"

import {OrderDirection} from "@/types"
import {ProposalsList} from "@/_modules/proposal/types"
import {FieldLike, TableRow} from "@/utils/vue-bootstrap-types"
import {ProposalItem, RequestItem} from "@/_modules/activity/assets/types"

import ApplicationConfiguration, {defaultPaginationItemsPerPage} from "@/constants/ApplicationConfiguration"
import * as Error from "@/utils/errors"
import {formatDate} from "@/utils/formatters"
import {BasicErrorHandler} from "@/utils/errorHandler";
import {ErrorCodes} from "@/constants/APIconstants";
import {BadgeEvent, badgesStore} from "@/store/badges-store";
import { getDealStatusColor } from "@/components/calendar/mixin"
import PageHeader from "@/components/layout/PageHeader"
import ContentSection from "@/components/layout/ContentSection"

export class ProposalsErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch (e.code) {
      case ErrorCodes.UserDoesNotHaveProfile:
        this.errorMessageKey = "err_user_does_not_have_profile"
        break
      case ErrorCodes.OperationIsNotAuthorized:
        this.errorMessageKey = "err_operation_is_not_authorized"
        break
      case ErrorCodes.UserDoesNotHaveRightToAccess:
        this.errorMessageKey = "err_user_does_not_have_rights_to_access"
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}
const proposalsErrorHandler = new ProposalsErrorHandler()

@Component({
  name: 'Proposals',
  components: {
    BIcon,
    BIconArrowLeft,
    BIconArrowRight,
    BIconPlusCircle,
    BIconSortDown,
    BIconSortDownAlt
  }
})
export default class extends BackendClientMixin {

  public currentPage = 1
  public sortBy: SortBy = SortBy.date
  public sortMode: OrderDirection = OrderDirection.DESC

  public proposalsList: ProposalsList = {
    nr: 0,
    size: 10,
    items: [],
    total: 0
  }

  public get fields(): FieldLike[] {
    return [
      {
        key: 'title',
        label: this.translation('title_proposals_list_title')
      },
      {
        key: 'createdAt',
        label: this.translation('title_proposals_list_created_at'),
        formatter: formatDate
      },
      {
        key: 'status',
        label: this.translation('title_proposals_list_status')
      }
    ]
  }

  public async mounted(): Promise<void> {
    await this.fetchProposalsList()
  }

  public async fetchProposalsList(): Promise<void> {
    const payload: PaginationPayload = {
      userId: authStore.authInfo!.userId!,
      pagination: {
        page: this.proposalsList.nr,
        size: this.proposalsList.size
      },
      order: {
        sortBy: this.sortBy,
        sortMode: this.sortMode
      }
    }
    this.proposalsList.nr = this.proposalsList.nr - 1
    this.proposalsList = await this.withRequest(activityStore.getProposals(payload))
  }

  public async redirectToProposal(item: ProposalItem): Promise<void> {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    const result = badges.find(it => it.docId === item.id)
    if (result !== undefined) {
      await this.withRequest(badgesStore.deleteBadgeCall(result.id), proposalsErrorHandler).then(() => {
        badgesStore.removeInternalBadgeEvent(result)
      })
    }
    await this.$router.push({
      path: `${ProposalRoutes.ProposalProvider.path}/${item.id}`
    })
  }

  public renderStatus(proposal: TableRow<RequestItem>): VNode {
    return (
      <div class="d-flex align-items-baseline">
        <span class={`deal-status-indicator bg-${getDealStatusColor(proposal.item.status)} mr-2`} aria-hidden="true" />
        {this.translation(`lbl_status_${proposal.item.status}`)}
        {this.defineRelativeExactBadge(proposal)}
      </div>
    )
  }

  private defineRelativeExactBadge(demandProposal: TableRow<ProposalItem>): (VNode | undefined)[] {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    return (
      badges.map((it) => {
        if (demandProposal.item.id === it.docId) {
          return (
            <b-badge
              variant="danger"
              class="badge-event-label"
            >
              {this.translation('lbl_badge_new')}
            </b-badge>
          )
        } else {
          return undefined
        }
      })
    )
  }

  public render(): VNode {
    return (
      <b-container fluid="xl">
        <b-row>
          <PageHeader
            headerClass="my-10 mt-lg-4"
            title={this.translation('menu_proposals')}
            wideHeader={true}
          >
            <p>{this.translation('proposals_description')}</p>
          </PageHeader>
        </b-row>
        <ContentSection>
          <b-row>
            <b-col cols="12">
              <TableComponent
                bordered={false}
                busy={this.busy}
                class="app-table"
                current-page={this.proposalsList.nr-1}
                empty-key="msg_table_empty"
                fields={this.fields}
                hover={true}
                id="proposals-table"
                items={this.proposalsList.items}
                per-page={defaultPaginationItemsPerPage}
                responsive="md"
                selectable
                striped={false}
                thead-class="app-table-thead-bordered"
                onRowClicked={(item: RequestItem) => {
                  this.redirectToProposal(item)
                }}
                scopedSlots={{
                  "head(title)": (row: TableRow<never>) => {
                    return (
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{row.field.label}</span>
                        <b-link
                          class="d-flex text-primary ml-auto"
                          onClick={() => {
                            this.sortMode = this.sortMode === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC
                            this.sortBy = SortBy.name
                            this.fetchProposalsList()
                          }}
                          aria-label={this.translation('table_sort_column')}
                        >
                          <b-icon aria-hidden="true" class="app-icon-lg" icon={(this.sortMode === OrderDirection.ASC && this.sortBy === SortBy.name) || this.sortBy !== SortBy.name ? 'sort-down-alt' : 'sort-down'}/>
                        </b-link>
                      </div>
                    )
                  },
                  "head(createdAt)": (row: TableRow<never>) => {
                    return (
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{row.field.label}</span>
                        <b-link
                          class="d-flex text-primary ml-auto"
                          onClick={() => {
                            this.sortMode = this.sortMode === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC
                            this.sortBy = SortBy.date
                            this.fetchProposalsList()
                          }}
                          aria-label={this.translation('table_sort_column')}
                        >
                          <b-icon aria-hidden="true" class="app-icon-lg" icon={(this.sortMode === OrderDirection.ASC && this.sortBy === SortBy.date) || this.sortBy !== SortBy.date ? 'sort-down-alt' : 'sort-down'}/>
                        </b-link>
                      </div>
                    )
                  },
                  "cell(title)": (row: TableRow<ProposalItem>) => {
                    return (
                      <div class="ellipsis-string-container" v-b-tooltip={{title: row.item.name.length > ApplicationConfiguration.nameCropMidLength ? row.item.name : '', variant: 'primary'}}>
                        <span>{row.item.name}</span>
                      </div>
                    )
                  },
                  "cell(status)": (proposal: TableRow<ProposalItem>) => {
                    return (
                      <div>
                        {this.renderStatus(proposal)}
                      </div>
                    )
                  }
                }}
              />
            </b-col>
            {this.proposalsList.total > defaultPaginationItemsPerPage &&
              <b-col cols="12">
                <b-pagination
                  align="center"
                  aria-controls="proposals-table"
                  class="mt-6 mt-md-10 mb-0"
                  first-number
                  last-number
                  pills
                  per-page={defaultPaginationItemsPerPage}
                  total-rows={this.proposalsList.total}
                  value={this.currentPage}
                  onChange={(v: number) => {
                    this.proposalsList.nr = v - 1
                    this.currentPage = v
                    this.fetchProposalsList()
                  }}
                >
                  <template slot="prev-text"><b-icon-arrow-left /></template>
                  <template slot="next-text"><b-icon-arrow-right /></template>
                </b-pagination>
              </b-col>
            }
          </b-row>
        </ContentSection>
      </b-container>
    )
  }
}
